.backgroundImage1 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.backgroundImage2 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.backgroundImage3 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.backgroundImage3 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.backgroundImage5 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .backgroundImage1 {
    background-image: url("../../assets/bg-personal-info-1.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }

  .backgroundImage2 {
    background-image: url("../../assets/bg-third-step.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }

  .backgroundImage3 {
    background-image: url("../../assets/bg-fourth-step.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }

  .backgroundImage4 {
    background-image: url("../../assets/bg-fouth-step.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }

  .backgroundImage5 {
    background-image: url("../../assets/bg-finaly.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }
}
