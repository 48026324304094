.backgroundImage {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .backgroundImage {
    background-image: url("../../assets/bgOrganization.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
  }
}
